// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCSlm9gmgoHl1rSuZs1oqH9FF4H49_oQZ8",
  authDomain: "indbnew2.firebaseapp.com",
  databaseURL: "https://indbnew2-default-rtdb.firebaseio.com",
  projectId: "indbnew2",
  storageBucket: "indbnew2.appspot.com",
  messagingSenderId: "1009350216336",
  appId: "1:1009350216336:web:539e89f3fbd35b58ba6615",
  measurementId: "G-RL9WJJ7M3Z"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };